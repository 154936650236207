import React from 'react';
import { graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import CTAForm from 'components/CTAForm';
import BundleContainer from 'components/ProductKits';
import ScrollToTopButton from 'components/ScrollToTopButton';

const propTypes = {
  data: PropTypes.object.isRequired,
};


class BundlePageTemplate extends React.Component {

  render() {
    const { data, location } = this.props;
    const activeType = location && location.state ? location.state.activeType : null;
    return (
      <Layout location={location} type="standard">
        <SEO
          title={data.bundlePagesYaml.title}
          meta={data.bundlePagesYaml.metaTags}
          titleTemplate="%s"
        />
        <BundleContainer activeType={activeType} data={data} />
        <CTAForm />
        <ZipGateContainer location={location}/>
        <ScrollToTopButton />
      </Layout>
    );
  }
}
BundlePageTemplate.propTypes = propTypes;

export default BundlePageTemplate;

export const pageQuery = graphql`
  query BundlePagesQuery($id: String!) {
    bundlePagesYaml(id: {eq: $id}) {
      id
      path
      title
      productId
      compareSolutionsHash
      metaTags {
        name
        content
      }
      bundle {
        new
        customizeButton
        compareLink {
          text
          url
        }
      }
      types {
        type
        bigInfoCards {
          color
          type
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 891, maxHeight: 521) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        iconsBlock {
          icons {
            text
            icon
          }
        }  
        callUs
        bundleHowItWorks {
          title
          items {
            image {
              publicURL
            }
            preTitle
            title
            description
          }
        }
        infoBlock {
          title
          infoCards {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 535, maxHeight: 405) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        terms {
          title
          text
          closeButton
        }
        disclaimer {
          plansPriceText
          plansText
          plansButton
          planId
          termsText
          termsButton
          termsPostfix
        }
      }
    }
  }
`
